$color-primary: #00bfff;
$color-primary-light: #87cefa;
$color-primary-dark: #111e6c;
$color-primary-dark-2: #8c94ba;

$color-white: #fff;

$color-yellow: #f5c518;

$color-grey-light-1: #d3d3d3;

$color-grey-dark-1: #333;
$color-grey-dark-2: #777;

$color-dark-primary: #081c22;

$color-warning: #f08080;

$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-light: 0 2px 8px rgba(0, 0, 0, 0.1);

@mixin shrinkLabel {
  top: -14px;
  font-size: 12px;
  color: $color-primary;
}

* {
  padding: 0;
  margin: 0;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media screen and (max-width: 1000px) {
  html {
    font-size: 55%;
  }
}

@media screen and (max-width: 800px) {
  html {
    font-size: 50%;
  }
}
@media screen and (max-width: 600px) {
  html {
    font-size: 45%;
  }
}

body {
  font-family: 'Open Sans', sans-serif;
}

a {
  color: inherit;
}

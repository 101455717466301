@import '../../sass/styles';

.footer {
  height: 3rem;
  display: flex;
  justify-content: center;
  background: $color-primary-dark !important;
  color: $color-white;
  margin-top: 50px;
  width: 100%;

  p {
    margin-top: auto;
    text-transform: capitalize;
    font-size: 2rem;
    span {
      color: $color-primary-light;
    }
  }
}
